<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12">
        <prompt
          :active="createView"
          :title="'Select view type'"
          @close="createView = false"
        >
          <div slot="content">
            <div class="d-flex justify-content-center m-5">
              <router-link
                :to="{ name: 'order.view.create' }"
                class="btn mr-3 btn-outline-secondary"
              >
                Orders
              </router-link>
              <router-link
                :to="{ name: 'contact.view.create' }"
                class="btn mr-3 btn-outline-secondary"
              >
                Contacts
              </router-link>
            </div>
          </div>
        </prompt>
        <div class="row">
          <div class="col-12 col-md-3">
            <ConfigurationNav />
          </div>
          <div class="col-12 col-md-9">
            <HelpModal class="position-fixed p-4 justify-content-between" v-if="helpModal" @close="openHelp">
              <p>
                create a new view where you decide which columns are shown in the order overview
              </p>
            </HelpModal>
            <div class="card border-0 shadow-sm">
              <div class="card-header">
                <h4>
                  Views
                  <button @click="createView = true"
                    class="btn btn-sm float-right btn-outline-secondary m-1"
                  >
                    Create a view
                  </button>
                </h4>
              </div>
              <div
              >
                <div
                  v-if="Object.keys(views.data).length !== 0"
                  class="card-body"
                >
                  <data-table
                    :headers="headers()"
                    sort-url="/configuration/view"
                    :method="'get'"
                    :data="views.data"
                  >
                    <template #name="{ item }">
                      {{ item.data.name }}
                    </template>
                    <template #view_type="{ item }">
                      {{ item.data.view_type }}
                    </template>
                    <template #default="{ item }">
                      <input
                        type="radio"
                        :name="item.data.view_type"
                        :value="item.id"
                        :checked="item.data.default"
                        @click="setDefault(item)"
                      />
                    </template>
                    <template #created_at="{ item }">
                      {{ item.created_at | formatLocaleDateString }}
                    </template>
                    <template #updated_at="{ item }">
                      {{ item.updated_at | formatLocaleDateString }}
                    </template>
                    <template #actions="{ item }">
                      <div>
                        <div v-if="item.data.view_type === 'customer'">
                          <router-link
                            v-if="canEdit(item)"
                            :to="{
                              name: 'contact.view',
                              params: { id: item.id },
                              query: { edit: true },
                            }"
                            class="btn btn-sm btn-outline-secondary m-1"
                          >
                            Edit
                          </router-link>
                        </div>
                        <div v-else>
                          <router-link
                            v-if="canEdit(item)"
                            :to="{
                              name: 'order.view',
                              params: { id: item.id },
                              query: { edit: true },
                            }"
                            class="btn btn-sm btn-outline-secondary m-1"
                          >
                            Edit
                          </router-link>
                        </div>
                        <DeleteButton
                          v-if="canDelete(item) && (item.id !== defaultCustomerViewId && item.id !== defaultOrderViewId)"
                          :uri="`/configurationData/${item.id}`"
                          :name="item.data.name"
                          :isSmall="true"
                          @deleted="deleteContent(item.id)"
                        />
                      </div>
                    </template>
                  </data-table>
                  <pagination
                    :data="views"
                    :limit="3"
                    :show-disabled="true"
                    align="right"
                    @pagination-change-page="getViews"
                  >
                    <span slot="prev-nav" class="text-capitalize"
                      ><i class="fas fa-chevron-left mr-2" /> Prev</span
                    >
                    <span slot="next-nav" class="text-capitalize"
                      >Next<i class="fas fa-chevron-right ml-2"
                    /></span>
                  </pagination>
                </div>
                <div v-else class="card-body">
                  <p class="text-muted">
                    This is a list of your views.
                  </p>
                  <div class="p-4 rounded border-zip text-center">
                    <p class="mb-0">
                      No views available!
                    </p>
                  </div>
                </div>
              </div>
              <div class="page-help">
                <a @click="openHelp">
                  <QuestionCircle />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import Permission from "@/utils/permission";
import DataTable from "@/components/DataTable/DataTable.vue";
import Default from "@/layouts/default.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
import DeleteButton from "@/components/Form/DeleteButton.vue";
import QuestionCircle from "@/components/Svg/QuestionCircle.vue";
import HelpModal from "@/components/UI/HelpModal.vue";
import Prompt from "@/components/UI/Prompt.vue";

export default {
  components: {
    DataTable,
    Default,
    ConfigurationNav,
    DeleteButton,
    QuestionCircle,
    HelpModal,
    Prompt,
  },
  extends: Permission,
  beforeRouteEnter(to, from, next) {
    let uri = "/configuration/view?page=1";

    if (to.query.sort && to.query.dir) {
      uri += "&sort=" + to.query.sort + "&dir=" + to.query.dir;
    }

    jsonAPI.get(uri).then((response) => {
      next((vm) => {
        vm.views = response.data;
        vm.views.data.forEach((view) => {
          if (view.data.default) {
            if(view.data.view_type === 'customer') {
              vm.defaultCustomerViewId = view.id;
            }else {
              vm.defaultOrderViewId = view.id;
            }
          }
        });
      });
    });
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      views: {
        data: [],
        meta: {
          total: 0,
        },
        links: {},
      },
      viewId: "",
      defaultViewId: "",
      helpModal: false,
      defaultOrderViewId: "",
      defaultCustomerViewId: "",
      createView: false,
    };
  },
  methods: {
    headers() {
      return [
        { title: "Name", key: "name", sortable: true, sortKey: "name" },
        {
          title: "View Type",
          key: "view_type",
          sortable: false,
          sortKey: "view_type",
        },
        {
          title: "Default",
          key: "default",
          sortable: false,
          sortKey: "default",
        },
        {
          title: "Created on",
          key: "created_at",
          sortable: true,
          sortKey: "created_at",
        },
        {
          title: "Last updated",
          key: "updated_at",
          sortable: true,
          sortKey: "updated_at",
        },
        { title: "Actions", key: "actions" },
      ];
    },
    canEdit(view) {
      if (view) {
        if (view.owner_id) {
          return true;
        }
        if (view.role) {
          return this.hasPermission("edit-role-view");
        }
        return this.hasPermission("edit-organization-view");
      }
      return false;
    },
    canDelete(view) {
      if (view) {
        if (view.owner_id) {
          return true;
        }
        if (view.role) {
          return this.hasPermission("delete-role-view");
        }
        return this.hasPermission("delete-organization-view");
      }
    },
    getViews: function(page = 1) {
      let uri = "/configuration/view?page=" + page;

      if (this.$route.query.sort && this.$route.query.dir) {
        uri +=
          "&sort=" + this.$route.query.sort + "&dir=" + this.$route.query.dir;
      }

      jsonAPI
        .get(uri)
        .then((response) => {
          this.views = response.data;
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: "Error fetching results",
            type: "error",
          });
        });
    },
    deleteContent(id) {
      this.views.data = this.views.data.filter((obj) => {
        return obj.id !== id;
      });
    },
    async setDefault(view) {
      await this.views.data.forEach((item) => {
        const uri = "/configurationData/" + item.id;
        if(view.data.view_type === 'customer') {
          if (item.id === this.defaultCustomerViewId) {
            item.data.default = false;
            jsonAPI.put(uri, item);
          }
        } else {
          if (item.id === this.defaultOrderViewId) {
            item.data.default = false;
            jsonAPI.put(uri, item);
          }
        }
      });
      const uri = "/configurationData/" + view.id;
      view.data.default = true;
      await jsonAPI.put(uri, view);
      if(view.data.view_type === 'customer') {
        this.defaultCustomerViewId = view.id;
      } else {
        this.defaultOrderViewId = view.id;
      }
    },
    openHelp() {
      this.helpModal = !this.helpModal;
      window.scrollTo(0,0);
    },
  },
};
</script>

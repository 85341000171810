var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.layout,{tag:"component"},[_c('div',{staticClass:"row fadeInUp",attrs:{"role":"main"}},[_c('div',{staticClass:"col-12"},[_c('prompt',{attrs:{"active":_vm.createView,"title":'Select view type'},on:{"close":function($event){_vm.createView = false}}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"d-flex justify-content-center m-5"},[_c('router-link',{staticClass:"btn mr-3 btn-outline-secondary",attrs:{"to":{ name: 'order.view.create' }}},[_vm._v(" Orders ")]),_c('router-link',{staticClass:"btn mr-3 btn-outline-secondary",attrs:{"to":{ name: 'contact.view.create' }}},[_vm._v(" Contacts ")])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-3"},[_c('ConfigurationNav')],1),_c('div',{staticClass:"col-12 col-md-9"},[(_vm.helpModal)?_c('HelpModal',{staticClass:"position-fixed p-4 justify-content-between",on:{"close":_vm.openHelp}},[_c('p',[_vm._v(" create a new view where you decide which columns are shown in the order overview ")])]):_vm._e(),_c('div',{staticClass:"card border-0 shadow-sm"},[_c('div',{staticClass:"card-header"},[_c('h4',[_vm._v(" Views "),_c('button',{staticClass:"btn btn-sm float-right btn-outline-secondary m-1",on:{"click":function($event){_vm.createView = true}}},[_vm._v(" Create a view ")])])]),_c('div',[(Object.keys(_vm.views.data).length !== 0)?_c('div',{staticClass:"card-body"},[_c('data-table',{attrs:{"headers":_vm.headers(),"sort-url":"/configuration/view","method":'get',"data":_vm.views.data},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.name)+" ")]}},{key:"view_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.view_type)+" ")]}},{key:"default",fn:function(ref){
var item = ref.item;
return [_c('input',{attrs:{"type":"radio","name":item.data.view_type},domProps:{"value":item.id,"checked":item.data.default},on:{"click":function($event){return _vm.setDefault(item)}}})]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocaleDateString")(item.created_at))+" ")]}},{key:"updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocaleDateString")(item.updated_at))+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.data.view_type === 'customer')?_c('div',[(_vm.canEdit(item))?_c('router-link',{staticClass:"btn btn-sm btn-outline-secondary m-1",attrs:{"to":{
                            name: 'contact.view',
                            params: { id: item.id },
                            query: { edit: true },
                          }}},[_vm._v(" Edit ")]):_vm._e()],1):_c('div',[(_vm.canEdit(item))?_c('router-link',{staticClass:"btn btn-sm btn-outline-secondary m-1",attrs:{"to":{
                            name: 'order.view',
                            params: { id: item.id },
                            query: { edit: true },
                          }}},[_vm._v(" Edit ")]):_vm._e()],1),(_vm.canDelete(item) && (item.id !== _vm.defaultCustomerViewId && item.id !== _vm.defaultOrderViewId))?_c('DeleteButton',{attrs:{"uri":("/configurationData/" + (item.id)),"name":item.data.name,"isSmall":true},on:{"deleted":function($event){return _vm.deleteContent(item.id)}}}):_vm._e()],1)]}}],null,false,4261161264)}),_c('pagination',{attrs:{"data":_vm.views,"limit":3,"show-disabled":true,"align":"right"},on:{"pagination-change-page":_vm.getViews}},[_c('span',{staticClass:"text-capitalize",attrs:{"slot":"prev-nav"},slot:"prev-nav"},[_c('i',{staticClass:"fas fa-chevron-left mr-2"}),_vm._v(" Prev")]),_c('span',{staticClass:"text-capitalize",attrs:{"slot":"next-nav"},slot:"next-nav"},[_vm._v("Next"),_c('i',{staticClass:"fas fa-chevron-right ml-2"})])])],1):_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-muted"},[_vm._v(" This is a list of your views. ")]),_c('div',{staticClass:"p-4 rounded border-zip text-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" No views available! ")])])])]),_c('div',{staticClass:"page-help"},[_c('a',{on:{"click":_vm.openHelp}},[_c('QuestionCircle')],1)])])],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }